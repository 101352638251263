import React from "react";
import s from "./paypalPages.module.css"

const CompliteDonationPage = () => {

    return (
        <div className={s.wrap}>
            <h1>Thanks for your support us :)</h1>
        </div>
    )
}

export default CompliteDonationPage;